import { query as queryUsers, queryCurrent } from "@/services/user";
import { setAuthority } from '@/utils/authority'
import { queryConfigs } from "@/services/basicSettings";

export default {
  namespace: "user",
  state: {
    list: [],
    currentUser: {},
    userAuthority: [],
    systemConfig: {},
    userPermissions: []
  },
  effects: {
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers);
      yield put({
        type: "save",
        payload: response,
      });
    },
    *fetchCurrent(_, { call, put }) {
      const { data, code } = yield call(queryCurrent);
      let userRole = []
      if(code === 0) {
        if(data.role === '在线医助') {
          userRole.push('assiant')
        } else if(data.role === '线下导诊') {
          userRole.push('guidance')
        } else {
          userRole.push('admin')
        }
        setAuthority(userRole)
        yield put({
          type: "updateState",
          payload: {
            userAuthority: userRole,
          },
        });
        yield put({
          type: "saveCurrentUser",
          payload: data,
        });

        // 为嵌入的iframe登录，存储登录参数
        if (data.jobPosition == '店长') {
          const clinic = data.organizationResps.filter(i => i.level === 3 && i.name == '店长')
          const loginData = {
            clinicId: clinic[0].id, // 门诊ID
            orgName: clinic[0].parentName, // 门诊名称
            phone: data.phone // 手机号码
          }
          sessionStorage.setItem('loginData', JSON.stringify(loginData))
        } else {
          const loginData = {
            clinicId: '', // 门诊ID
            orgName: '', // 门诊名称
            phone: '' // 手机号码
          }
          sessionStorage.setItem('loginData', JSON.stringify(loginData))
        }

      }
      // const resConfig = yield call(queryConfigs, { type: 'SYSTEM_CONFIG_ITEM' });
      // if(resConfig && resConfig.code === 0) {
      //   const configData = {}
      //   if(resConfig.data && resConfig.data.length > 0) {
      //     resConfig.data.forEach(_ => configData[_.refKey] = _.refValue)
      //   }

      //   yield put({
      //     type: "updateState",
      //     payload: {
      //       systemConfig: configData
      //     },
      //   });
      // }
      return {
        userRole
      }
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload || {},
      };
    },
    saveUserAuthority(state, action) {
      return {
        ...state,
        userAuthority: action.payload || [],
      };
    },
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};
